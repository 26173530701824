import {
  PRODUCT_QUERY_COLORS,
  PRODUCT_QUERY_EFFECTS,
  PRODUCT_QUERY_FIRING_PATTERNS,
  PRODUCT_QUERY_DEBRIS_PROFILES,
  PRODUCT_QUERY_CALIBERS,
  PRODUCT_QUERY_PERFORMANCE_HEIGHTS,
  PRODUCT_QUERY_PRICE,
  PRODUCT_QUERY_SORT,
  PRODUCT_QUERY_PAGE,
  PRODUCT_QUERY_SEARCH,
  ALL_FILTER_QUERY_GROUPS_IN_ORDER,
  ALL_FILTER_URL_GROUPS_IN_ORDER,
  DEFAULT_PL_META_DESCRIPTION,
  DEFAULT_WS_META_DESCRIPTION,
  DEFAULT_PL_TITLE,
  DEFAULT_WS_TITLE,
} from '../constants/constants'
import { sortByDefaultSetting } from '../constants/db'
import { PUBLIC_API_URL } from '../context/apiconfig'
import { IUserProfile } from '../queries/user/types'
import history from '../context/history'
import { SHOP_LIST_PATH } from '../constants/constants'
import { isProd } from './EnvDetect'
import { HIDE_GLOBAL_HEADER_PATHS } from '../routes'
import { isEmpty, join } from 'lodash'
import { Location } from 'history'
import { IProductFilter } from '../queries/products/types'
import { IProductFilterOption } from '../queries/filters/types'
import { ISeoMeta } from '../types/interfaces'

export const CART_PRODUCT_UPDATE_DEBOUNCE_DELAY = 750
export const DEFAULT_DEBOUNCE_DELAY = 300
export const PRODUCTS_PER_PAGE = 50
export const ORDERS_PER_PAGE = 5

export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID
  ? process.env.REACT_APP_PROJECT_ID
  : '11' // default to 11 / wholesale as backup incase a project doesn't have an up-to-date .env file

export const onDocClick = (
  fileType: string,
  documentName: string,
  orders_id: string | undefined,
) => {
  window.location.href = `${PUBLIC_API_URL}documents/${fileType}/${documentName}/${orders_id}`
}

export const onPricelistDocClick = (listName: string, sortWord: string) => {
  const urlSuffix = isProline()
    ? `${listName}-${sortWord}-proline`
    : `${listName}-${sortWord}`
  if (isProd()) {
    window.location.href = `${PUBLIC_API_URL}documents/s3/pricelist/${urlSuffix}`
  } else {
    window.location.href = `${PUBLIC_API_URL}documents/s3/pricelist/${urlSuffix}/isNotProd`
  }
}

export const onExcelDocClick = (
  getSpecifications: string,
  listName: string,
  levelId: number,
) => {
  window.location.href = `${PUBLIC_API_URL}documents/${getSpecifications}/${listName}/${levelId}/${PROJECT_ID}`
}

//Validators
export const validatePhoneNumber = (value: string | undefined) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/gi, '')
  if (phoneNumber === '') {
    return true
  } else if (/^(\+?1)|(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
    return true
  } else {
    return false
  }
}

//AutoMasking Mobile & Phone Numbers
export const automaskMobilePhoneNumber = (value: string) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/g, '')
  if (phoneNumber.length === 10) {
    phoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '+1($1) $2-$3')
  } else if (phoneNumber.length === 13) {
    phoneNumber = phoneNumber.replace(
      /^(\+?1)(\d{3})[ ](\d{3})(\d{4})$/,
      '$1($2) $3-$4',
    )
  } else {
    if (/^(\+?1)(\d{3})[ ](\d{3})(\d{4})./.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 13)
    } else if (/^(\+?1)(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 11)
    }
  }
  return phoneNumber
}

export const automaskPhoneNumber = (value: string) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/g, '')
  if (phoneNumber.length === 10) {
    phoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
  } else if (phoneNumber.length === 11) {
    phoneNumber = phoneNumber.replace(
      /^(\d{3})[ ](\d{3})(\d{4})$/,
      '($1) $2-$3',
    )
  } else {
    if (/^(\d{3})[ ](\d{3})(\d{4})./.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 11)
    } else if (/^(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 10)
    }
  }
  return phoneNumber
}

export const automaskEmail = (value: string) => {
  return new Promise<string>((resolve) => {
    let email = typeof value === 'undefined' ? '' : value
    email = email.replace(/\s/g, '')
    resolve(email)
  })
}

export const priceApiToCss = (object: any) => {
  let bootstrapSting = ''
  if (object.strikethrough > 0) {
    bootstrapSting += ' text-decoration-line-through'
  }
  if (object.emphasize > 0) {
    bootstrapSting += ' fw-bold text-accent'
  }
  if (object.muted > 0) {
    bootstrapSting += ' text-muted'
  }
  if (object.small) {
    bootstrapSting += ' small'
  }

  return bootstrapSting
}

export const determineChanges = (
  oldValues: any,
  newValues: any,
  hiddenInputs: Array<string> = [],
) => {
  let tempChangedValues: any = {}
  hiddenInputs.forEach((input_name) => {
    tempChangedValues[input_name] = oldValues[input_name]
  })

  for (const property in newValues) {
    if (
      newValues[property] !== oldValues[property] &&
      newValues[property] !== undefined
    ) {
      tempChangedValues = {
        ...tempChangedValues,
        ...{ [property]: newValues[property] },
      }
    }
  }

  return tempChangedValues
}

export const stringToBoolean = (
  stringValue: string | undefined | null | boolean,
) => {
  if (typeof stringValue === 'string') {
    const lowerCaseValue = stringValue.toLowerCase().trim()
    switch (lowerCaseValue) {
      case 'true':
      case 'yes':
      case '1':
        return true

      case 'false':
      case 'no':
      case '0':
      case null:
      case undefined:
        return false

      default:
        return false
    }
  } else if (typeof stringValue === 'number') {
    if (stringValue === 1) {
      return true
    } else if (stringValue === 0) {
      return false
    }
  }
  return stringValue ?? false
}

interface KeyboardEvent {
  key: string
}
export const blurInputOnKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }
}

export const buildFilterFromLocation = (location: Location) => {
  const { categoryUrls, brandUrls, listUrls } = buildSearchArraysFromPathname(
    location.pathname,
  )
  const urlSearchParams = new URLSearchParams(location.search)

  return {
    filterReady: true,
    search_term: urlSearchParams.get(PRODUCT_QUERY_SEARCH) || '',
    category_url: categoryUrls,
    brand_url: brandUrls,
    list_url: listUrls,
    color: urlSearchParams.get(PRODUCT_QUERY_COLORS)?.split(','),
    effect: urlSearchParams.get(PRODUCT_QUERY_EFFECTS)?.split(','),
    firing_pattern: urlSearchParams
      .get(PRODUCT_QUERY_FIRING_PATTERNS)
      ?.split(','),
    debris_profile: urlSearchParams
      .get(PRODUCT_QUERY_DEBRIS_PROFILES)
      ?.split(','),
    caliber: urlSearchParams.get(PRODUCT_QUERY_CALIBERS)?.split(','),
    performance_height: urlSearchParams
      .get(PRODUCT_QUERY_PERFORMANCE_HEIGHTS)
      ?.split(','),
    price: urlSearchParams.get(PRODUCT_QUERY_PRICE)?.split(','),

    sort: urlSearchParams.get(PRODUCT_QUERY_SORT) || sortByDefaultSetting,

    page: urlSearchParams.get(PRODUCT_QUERY_PAGE) || 1,
    items_per_page: PRODUCTS_PER_PAGE,
  }
}

export const updatedProductsAfterQuantityChange = (
  response: any,
  existingProductsList: any,
) => {
  return existingProductsList.map(
    (product: { products_id: any; final_price: number }) =>
      product?.products_id === response?.data?.data?.products_id
        ? {
            ...product,
            id: response.data.data.id,
            quantity: response.data.data.quantity,
            total_price: response.data.data.quantity * product.final_price,
            success: response.data.success,
            status: response.data.status,
            message: response.data.message,
          }
        : product,
  )
}

export const updatedSingleProductAfterQuantityChange = (
  response: any,
  existingProduct: any,
) => {
  return existingProduct?.products_id === response?.data?.data?.products_id
    ? {
        ...existingProduct,
        id: response.data.data.id,
        quantity: response.data.data.quantity,
        total_price: response.data.data.quantity * existingProduct.final_price,
        success: response.data.success,
        status: response.data.status,
        message: response.data.message,
      }
    : existingProduct
}

export const getContactPhone = (user_profile: IUserProfile) => {
  if (user_profile.customers_mobile_phone) {
    return user_profile.customers_mobile_phone.slice(2)
  } else if (user_profile.customers_telephone) {
    return user_profile.customers_telephone
  } else {
    return null
  }
}

export const updateShopListUrl = (
  name: string,
  value: string,
  selected: boolean,
) => {
  history.push(`${buildNewUrl(name, value, selected)}`)
}

export const buildNewUrl = (name: string, value: string, selected: boolean) => {
  const pathname = history.location.pathname
  const searchQuery = history.location.search
  const newPathname = pathname.replace(value, '')
  const pathPartsArry = newPathname?.split('/')

  let currentContext: string = ''

  let urlSearchParams: any = {}
  ALL_FILTER_URL_GROUPS_IN_ORDER.forEach((groupName: string) => {
    urlSearchParams[groupName] = []
  })

  pathPartsArry.forEach((path: string) => {
    if (!path) {
      return
    }

    switch (true) {
      case path === SHOP_LIST_PATH:
        break
      case ALL_FILTER_URL_GROUPS_IN_ORDER.includes(path):
        currentContext = path
        break
      default:
        urlSearchParams[currentContext].push(path)
    }
  })

  let url = `/${SHOP_LIST_PATH}`

  if (selected) {
    ALL_FILTER_URL_GROUPS_IN_ORDER.forEach((groupName: string) => {
      if (groupName === name) {
        urlSearchParams[groupName].push(value)
        urlSearchParams[groupName].sort()
      }
    })
  }

  ALL_FILTER_URL_GROUPS_IN_ORDER.forEach((groupName: string) => {
    if (!isEmpty(urlSearchParams[groupName])) {
      url += `/${groupName}/${join(urlSearchParams[groupName], '/')}`
    }
  })

  if (searchQuery) {
    url += searchQuery
  }

  return url
}

export const updateShopListUrlParams = (
  name: string,
  value: string,
  selected: boolean = false,
  limitToOne: boolean = false,
) => {
  history.push(
    `${history.location.pathname}${buildNewUrlSearchQuery(
      name,
      value,
      selected,
      limitToOne,
    )}`,
  )
}

export const buildNewUrlSearchQuery = (
  name: string,
  value: string,
  selected: boolean,
  limitToOne: boolean = false,
) => {
  const searchParams = new URLSearchParams(history.location.search)
  let searchQuery: any = {}
  ALL_FILTER_QUERY_GROUPS_IN_ORDER.forEach((groupName: string) => {
    const valueString = searchParams.get(groupName)
    const valueArry = valueString?.split(',')

    let newValueArry: Array<string> = []

    if (groupName === name && limitToOne) {
      if (selected) {
        newValueArry = valueArry ? valueArry : []
      }
    } else {
      valueArry?.forEach((existingValue: string) => {
        if (groupName === PRODUCT_QUERY_SEARCH && !selected) {
          return
        }

        if (groupName !== name || existingValue !== value || selected) {
          newValueArry.push(existingValue)
        }
      })
    }

    searchQuery[groupName] = newValueArry ? newValueArry : []

    if (groupName === name && selected) {
      if (limitToOne) {
        searchQuery[groupName] = [value]
      } else {
        searchQuery[groupName].push(value)
        searchQuery[groupName].sort()
      }
    }
  })
  let queryString = '?'
  let delimiter = ''
  ALL_FILTER_QUERY_GROUPS_IN_ORDER.forEach((groupName: string) => {
    if (searchQuery[groupName].length > 0) {
      queryString += `${delimiter}${groupName}=${searchQuery[groupName]}`
      delimiter = '&'
    }
  })

  return queryString === '?' ? '' : queryString
}

export const buildSearchArraysFromPathname = (url: string) => {
  const pathPartsArry = url.split('/')
  let categoryUrls: Array<string> = []
  let brandUrls: Array<string> = []
  let listUrls: Array<string> = []
  let currentContext: string | null = null

  pathPartsArry.forEach((path: string) => {
    if (!path) {
      return
    }

    if (path === 'c') {
      currentContext = 'c'
    } else if (path === 'b') {
      currentContext = 'b'
    } else if (path === 'l') {
      currentContext = 'l'
    } else {
      switch (currentContext) {
        case 'c':
          categoryUrls.push(path)
          break
        case 'b':
          brandUrls.push(path)
          break
        case 'l':
          listUrls.push(path)
          break
      }
    }
  })
  return {
    categoryUrls,
    brandUrls,
    listUrls,
  }
}

export const getActiveFilterKeys = (
  location: Location,
  subsequentKey: string,
  subsequentKeyPlusOne: string,
) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const { categoryUrls, brandUrls, listUrls } = buildSearchArraysFromPathname(
    location.pathname,
  )
  const defaultActiveKeys: Array<string> = new Array()

  if (!isEmpty(categoryUrls)) {
    defaultActiveKeys.push('0')
  }

  if (!isEmpty(brandUrls)) {
    defaultActiveKeys.push('1')
  }

  if (isProline()) {
    if (urlSearchParams.get(PRODUCT_QUERY_COLORS)) {
      defaultActiveKeys.push('2')
    }

    if (urlSearchParams.get(PRODUCT_QUERY_EFFECTS)) {
      defaultActiveKeys.push('3')
    }

    if (urlSearchParams.get(PRODUCT_QUERY_FIRING_PATTERNS)) {
      defaultActiveKeys.push('4')
    }

    if (urlSearchParams.get(PRODUCT_QUERY_DEBRIS_PROFILES)) {
      defaultActiveKeys.push('5')
    }

    if (urlSearchParams.get(PRODUCT_QUERY_CALIBERS)) {
      defaultActiveKeys.push('6')
    }

    if (urlSearchParams.get(PRODUCT_QUERY_PERFORMANCE_HEIGHTS)) {
      defaultActiveKeys.push('7')
    }
  }

  if (urlSearchParams.get(PRODUCT_QUERY_PRICE)) {
    defaultActiveKeys.push(subsequentKey)
  }

  if (!isEmpty(listUrls)) {
    defaultActiveKeys.push(subsequentKeyPlusOne)
  }

  return defaultActiveKeys
}
// keep sequence in URL consistent so as to leverage data from Tanstack Query cache
export const buildNewUrlQuery = (
  searchParams: any,
  name: string,
  value: string,
  limitToOne?: boolean,
) => {
  const processSelectedFilterGroup = () => {
    let toggledArray: Array<string> = []

    if (searchParams.has(name)) {
      const subqueryTargetValues = searchParams.get(name)

      if (subqueryTargetValues.split(',').includes(value)) {
        if (subqueryTargetValues.length > 0) {
          if (limitToOne) {
            toggledArray = [value]
          } else {
            toggledArray = subqueryTargetValues
              .split(',')
              .filter(function (v: string) {
                return v !== value
              })
          }
        }
      } else {
        if (limitToOne) {
          toggledArray = [value]
        } else {
          toggledArray = [...subqueryTargetValues.split(','), value].sort(
            (a, b) => Number(a) - Number(b),
          )
        }
      }
    } else {
      toggledArray = [value]
    }

    return toggledArray
  }

  let tempQueryString: string = ''

  ALL_FILTER_QUERY_GROUPS_IN_ORDER.forEach((groupName: string) => {
    if (name !== groupName) {
      if (searchParams.get(groupName)?.length > 0) {
        tempQueryString += tempQueryString.length === 0 ? '?' : '&'
        tempQueryString += `${groupName}=${searchParams.get(groupName)}`
      } else {
        // do nothing
      }
    } else {
      const updatedIds = processSelectedFilterGroup()

      if (updatedIds?.length) {
        if (
          groupName === PRODUCT_QUERY_SORT &&
          value === sortByDefaultSetting.toString()
        ) {
          // do nothing, so we keep default Sort By out of url
        } else {
          tempQueryString += tempQueryString.length === 0 ? '?' : '&'
          tempQueryString += `${groupName}=${updatedIds}`
        }
      } else {
        // do nothing
      }
    }
  })

  return tempQueryString
}

interface ISeoMetaProps {
  filter: IProductFilter
  categories: Array<IProductFilterOption>
  brands: Array<IProductFilterOption>
  lists: Array<IProductFilterOption>
  suffix: string | undefined
}

export const getSeoMeta = (props: ISeoMetaProps): ISeoMeta => {
  const { filter, categories, brands, lists, suffix } = props
  let metaDescription = null
  let title = null

  if (categories && filter.category_url && filter.category_url.length > 0) {
    const category_url = filter.category_url[0]
    categories.forEach((category: IProductFilterOption) => {
      if (category_url === category.value) {
        metaDescription = category.seo_meta
        title = category.seo_title
        return
      }
    })
  } else if (brands && filter.brand_url && filter.brand_url.length > 0) {
    const brand_url = filter.brand_url[0]
    brands.forEach((brand: IProductFilterOption) => {
      if (brand_url === brand.value) {
        metaDescription = brand.seo_meta
        title = brand.seo_title
        return
      }
    })
  } else if (lists && filter.list_url && filter.list_url.length > 0) {
    const list_url = filter.list_url[0]
    lists.forEach((list: IProductFilterOption) => {
      if (list_url === list.value) {
        metaDescription = list.seo_meta
        title = list.seo_title
        return
      }
    })
  }

  return {
    metaDescription: metaDescription
      ? metaDescription
      : isProline()
      ? DEFAULT_PL_META_DESCRIPTION
      : DEFAULT_WS_META_DESCRIPTION,
    title: title
      ? `${title}${suffix ? suffix : ''}`
      : isProline()
      ? DEFAULT_PL_TITLE
      : DEFAULT_WS_TITLE,
    rawTitle: title ? title : '',
    page_title: '',
  }
}

export const truncateText = (
  text: string,
  length: number,
  replace: string = '',
) => {
  if (text.length > length) {
    return text.substring(0, length) + replace
  }
  return text
}

export const isProline = () => {
  return PROJECT_ID === '14'
}

export const parseJwt = (token: string) => {
  if (!token) {
    return null
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonStr = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  return JSON.parse(jsonStr)
}

export const isImpersonate = () => {
  const userStr = localStorage.getItem('user')
  const user = userStr ? JSON.parse(userStr) : null
  const jwtPayload: any = parseJwt(user?.access_token)

  return jwtPayload?.is_impersonate
}

export const isHideGlobalHeaderPath = (path: string) => {
  return HIDE_GLOBAL_HEADER_PATHS.includes(path)
}
