import * as Yup from 'yup'

// Responsive Constants
export const generalBsBreakpoint = 'lg' //BootStrap sets the magic word "lg" for large screens at >=992px
export const generalBsBreakpointValue = 992 // should match above choice

export const mediumBsBreakpoint = 'md'
export const mediumBsBreakpointValue = 768

export const showAboveBreakpoint = `d-none d-${generalBsBreakpoint}-block`
export const showBelowBreakpoint = `d-${generalBsBreakpoint}-none`

export const savedCartsWithItemsLimitLanding = 2
export const openOrdersLimitLanding = 2

export const RECAPTCHA_SITE_KEY = '6LfKDz8UAAAAAIY7sLFdYlKO_MhN9qClLMCZr9mw'

export const initiallySelectedStoreSettings = [
  'recaptcha',
  'text_cart_contact_help',
  'terms_of_use_filename',
  'privacy_policy_filename',
  'show_upsell_on_wholesale',
  'show_upsell_on_pro_line',
  'text_hours_left_side',
  'show_cart_recommended_products',
  'cart_recommended_products_label',
  'spirit_phone_number',
  'proline_phone_number',
  'preferred_carrier_message',
] // any adds here will need to be made to IStoreSettingsData a well, and wholesale_api/app/Http/Controllers/SiteController.php

export const cartSortOptions = {
  model: '1',
  name: '2',
  quantity: '3',
}

export const keywordForVolumePricing = 'volume'
export const keywordForSpecialPricing = 'special'
export const keywordForContainerPricing = 'container'
export const spiritPhoneNumber = '(573) 447-1776'
export const prolinePhoneNumber = '(484) 403-1877'
export const spiritSupportEmail = 'service@76fireworks.com'
export const prolineSupportEmail = 'ProLine@76fireworks.com'
export const faxNumber = '(573) 447-1786'

export const excelRequestWarehouseName = 'boonville'
export const excelRequestStockStatus = 'onorder'

export const HEADER_HEIGHT_DESKTOP = 140

export const PRODUCT_QUERY_LEGACY_CATEGORIES = 'category'
export const PRODUCT_QUERY_LEGACY_MANUFACTURERS = 'brand'
export const PRODUCT_QUERY_LEGACY_PRODUCT_LISTS = 'list'
export const PRODUCT_QUERY_PAGE = 'page'
export const PRODUCT_QUERY_SORT = 'sortOptions'

// export const PRODUCT_QUERY_CATEGORIES = 'categories'
export const PRODUCT_QUERY_CATEGORIES = 'c'
// export const PRODUCT_QUERY_BRANDS = 'manufacturers'
export const PRODUCT_QUERY_BRANDS = 'b'
// export const PRODUCT_QUERY_LISTS = 'productLists'
export const PRODUCT_QUERY_LISTS = 'l'

export const PRODUCT_QUERY_COLORS = 'colors'
export const PRODUCT_QUERY_EFFECTS = 'effects'
export const PRODUCT_QUERY_FIRING_PATTERNS = 'firingPatterns'
export const PRODUCT_QUERY_DEBRIS_PROFILES = 'debrisProfiles'
export const PRODUCT_QUERY_CALIBERS = 'calibers'
export const PRODUCT_QUERY_PERFORMANCE_HEIGHTS = 'performanceHeights'
export const PRODUCT_QUERY_PRICE = 'priceFilters'
export const PRODUCT_QUERY_SEARCH = 'search'

export const ALL_FILTER_QUERY_GROUPS_IN_ORDER = [
  PRODUCT_QUERY_SEARCH,
  // PRODUCT_QUERY_CATEGORIES,
  // PRODUCT_QUERY_BRANDS,
  PRODUCT_QUERY_COLORS,
  PRODUCT_QUERY_EFFECTS,
  PRODUCT_QUERY_FIRING_PATTERNS,
  PRODUCT_QUERY_DEBRIS_PROFILES,
  PRODUCT_QUERY_CALIBERS,
  PRODUCT_QUERY_PERFORMANCE_HEIGHTS,
  PRODUCT_QUERY_PRICE,
  // PRODUCT_QUERY_LISTS,
  PRODUCT_QUERY_SORT,
  PRODUCT_QUERY_PAGE,
]

export const ALL_FILTER_URL_GROUPS_IN_ORDER = [
  PRODUCT_QUERY_CATEGORIES,
  PRODUCT_QUERY_BRANDS,
  PRODUCT_QUERY_LISTS,
]

export const DOCUMENT_TYPE_SALES_TAX = 'Sales Tax'
export const DOCUMENT_TYPE_PRO_USE = 'Pro Use'
export const DOCUMENT_TYPE_INSURANCE = 'Insurance'

export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

// export const SHOP_LIST_PATH = 'shoplist'
export const SHOP_LIST_PATH = 'sl'

export const initiallySelectedAppSettings = [
  'glow_enabled',
  'rush_shipping_in_cart_enabled',
  'aws_public_files_path',
]

export const currentYear = new Date().getFullYear()

export const passwordValidationSchema = Yup.string() // note: password is in 3 places in wholesale_frontend codebase
  .required('Required')
  .min(8, 'must be 8 characters or more')
  .matches(/[a-z]+/, 'must contain lowercase character')
  .matches(/[A-Z]+/, 'must contain uppercase character')
  .matches(/[!@#$%^&*()?><_+~{}`]+/, 'must contain special character')
  .matches(/\d+/, 'must contain number')

export const passwordRequirements = [
  ' 8 characters',
  'a lowercase',
  'an uppercase',
  'a special character',
  'a number',
]

export const passwordValidationTests = (inputValue: string) => {
  return [
    inputValue.length >= 8,
    /[a-z]+/.test(inputValue),
    /[A-Z]+/.test(inputValue),
    /[!@#$%^&*()?><_+~{}`]+/.test(inputValue),
    /\d+/.test(inputValue),
  ]
}

export const DEFAULT_WS_META_DESCRIPTION = `Industry-leading 1.4 fireworks and solutions for professional displays nationwide. Shop premium pyrotechnics and firing supplies with unmatched quality and expertise.`
export const DEFAULT_PL_META_DESCRIPTION = `Buy wholesale fireworks online at Spirit of '76, located in Mid-Missouri. We carry the hottest brands including Brothers, Winda, Sky Bacon, and Tako.`
export const DEFAULT_WS_TITLE = `Spirit of '76 Fireworks`
export const DEFAULT_PL_TITLE = `'76 Pro Line - America's #1 Pro Line`
