export interface ProductListData {
  has_video: boolean
  icons: Array<string>
  image_url: string
  instock: number
  is_multiple_products: number
  price_lines: Array<object>
  pro_use_only: boolean
  hide_pro_use: boolean
  products_id: number
  products_ignition: string | null
  products_model: string
  products_name: string
  products_packaging: string
  products_parcel_ship: boolean
  products_shots: number
  products_url: string
  stock_icon: string
  stock_msg: string
  onwater_arrival_date: string | null
  manufacturers_name: string
  id: number
  customers_carts_id: number
  quantity: number
  deal_text: string
  deal_icon_url: string
  is_promo?: boolean
  final_price: number
  total_price: number
  success: boolean
  status: string
  message: string
}

export interface IProductImage {
  id: number
  image_order: number
  image_name: string
  image_url: string
}

export interface IProductCaliber {
  id: number
  caliber_value: string
  caliber_units: string
}
export interface IProductColors {
  id: number
  color_name: string
  color_value: string
}

export interface IProductEffects {
  id: number
  effect_name: string
  effect_icon_value: string
}

export interface IProductFiringPatterns {
  id: number
  firing_pattern_name: string
  firing_pattern_icon_value: string
}

export interface IProductDebrisProfiles {
  id: number
  debris_profile_name: string
  debris_profile_icon_value: string
}

export interface IProductData extends ProductListData {
  id: number
  products_duration: string
  case_weight: number
  case_length: number
  case_width: number
  case_height: number
  dimensions: string
  products_description: string
  manufacturers_name: string
  categories_name: string
  products_video: string
  images: Array<IProductImage>
  related_products: Array<ProductListData>
  products_caliber: Array<IProductCaliber>
  products_colors: Array<IProductColors>
  products_effects: Array<IProductEffects>
  products_firing_patterns: Array<IProductFiringPatterns>
  products_debris_profiles: Array<IProductDebrisProfiles>
  performance_height: number
  net_explosive_quantity: number
  is_outdoor_use_only: boolean
  safety_distance: number
  products_ex_number: string
  products_un_number: string
  products_vdl: string
}

export enum IProductFilterType {
  PATH,
  PARAM,
}
export interface IProductFilterOption {
  value: string
  label: string
  type: IProductFilterType
  color: string
  icon: string
  seo_meta: string
  seo_title: string
}
